import React from 'react';
import { Container, Divider, Paper, Typography } from '@mui/material';

export default function ReleaseNotes() {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.5.0</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.5.0  Rückrufnummer:</strong> Es werden nur noch gültige Telefonummern zugelassen. Ausserdem ist die Eingabe der Rückrufnummer Pflicht.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.5.0  Transport Status:</strong> Die Begriffe für die Transport-Stati wurden angepasst. So wurde z.B. aus "Freigegeben" der Ausdruck "Akzeptiert", usw.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.4.2</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.4.2  Stammdaten Patienten:</strong> Patienten werden pro Institution des Users gespeichert und können auf der Seite "Patient" ausgewählt werden.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.4.0</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.4.0  Rolle</strong> für Benutzer DIALYSE eingeführt.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.4.0  Regelmäßige (Wiederkehrende) Transporte:</strong> mit Berechtigung der Rolle DIALYSE oder DISPONENT können Regelmäßige Transporte angelegt werden.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.4.0  Storno-Grund:</strong> beim Stornieren von Transporten wird ein Storno-Grund abgefragt und zu Opheo übertragen.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.3.2</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.3.2 </strong> Ambulante Fahrt: Bei ambulanter Fahrt wird automatisch eine Rückfahrt generiert.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.3.2 </strong> Neue Transportart: Dialysefahrt. Regeln analog zur Terminfahrt, mit Export an Opheo.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.3.2 </strong> Transport sitzend: Bestätigung über private Kostenübernahme erforderlich.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.3.0</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.3.0 </strong> Stammdaten-Import aus der Leitstelle: Objekte (Institutionen) werden stündlich aus dem Datensatz der LS importiert.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.3.0 </strong> Stammdaten Institutionen: Filter nach manuell- oder importierten Datensätzen.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Release Notes - Version 1.2.6</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.6 </strong> In der Tabelle für offenen Bestellungen wird nun auch das Feld (angelegt am) für USER angezeigt.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.5 </strong> Die Zeitspanne zum Auto-Logoff wurde von 1h auf 24h erhöht.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.5 </strong> Die Tabelle Bestellungen wird automatisch alle 5 Minuten aktualisiert.</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.4 Hinzugefügt:</strong> Text auf Startseite: In akuten medizinischen Notfällen wenden sie sich an die 112</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.4 </strong> Anstatt "Sauerstoffquelle ab" heisst es jetzt "Sauerstoff ab"</li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.4 </strong> Tabelle Bestellungen: Spalten für Von- und Nach- Adresse breiter. Neue Zeile für Station.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ marginBottom: '8px' }}>Release Notes - Version 1.2.1</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.1 Hinzugefügt:</strong> Bei den Transportdetails kann nun angegeben werden, ob der Patient "sauerstoffpflichtig" ist. </li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.1 Hinzugefügt:</strong> Die Tabelle "Bestellungen" enthält nun ein kleines rotes Symbol bei infektiösen Patienten. </li>
                        <li style={{ marginBottom: '8px' }}><strong>1.2.1</strong> Jede Nacht um 00:15 Uhr werden abgelaufene "Bestellungen" automatisch gelöscht. </li>
                        <li style={{ marginBottom: '8px' }}><strong>Hinzugefügt:</strong> Bei der Gewichtsabfrage von über 150 kg wird nun der Vermerk "Bitte rufen Sie uns an" zusammen mit der Rufnummer 0751/19222 angezeigt.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Neu:</strong> Zwei zusätzliche Auswahlfelder: "Patient Monitorpflichtig?" und "Patient Absaugpflichtig?". Bei positiver Auswahl wird der Vorgang abgebrochen und ein Anruf bei der Rufnummer 0751/19222 empfohlen.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Änderung:</strong> Das Profil "Poweruser" wurde in "Disponent" umbenannt.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Entfernt:</strong> Das Feld "Anlass Transport" wurde gelöscht.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Entfernt:</strong> Das Feld "gehfähig" wurde gelöscht.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Aktualisiert:</strong> Das Auswahlkriterium "Liegt eine Verordnung vor?" wurde zu "Liegt eine gültige Verordnung vor?" aktualisiert.</li>
                        <li style={{ marginBottom: '8px' }}><strong>Hinzugefügt:</strong> Ein Informationsfeld bei der Auswahl einer gültigen Verordnung, das ein Musterbild einer Verordnung mit einem angekreuzten KTW-Feld zeigt.</li>
                    </ul>
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7', marginTop: '40px' }}>
                <Typography variant="h6" color="#E46450" sx={{ marginBottom: '8px' }}>Release Notes - Version 1.1.0</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <ul>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Geburtsdatum wird validiert (darf nicht in der Zukunft liegen und Pat darf nicht älter als 120 Jahre sein)</li>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Der Zeitpunkt "Wann" wird validiert (darf nicht in der Vergangenheit liegen und nicht innerhalb der nächsten Stunde)</li>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Von/Nach - bei Klick auf "Weiter" wird der Tab "Zieladresse" aktiviert, wenn die Zieladresse noch nicht eingegeben wurde</li>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Wenn Pat Gewicht größer 150Kg, dann Fehlertext direkt bei der Eingabe oben im Screen: "Der Transport kann nicht für Patienten mit einem Gewicht von 150 kg oder mehr angefordert werden. Bitte rufen Sie uns an!"</li>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Von/Nach - Löschen von Institutionen innerhalb der Auswahlbox jetzt möglich</li>
                        <li style={{ marginBottom: '8px' }}><strong>Eingabe Bestellung:</strong> Transport-Details - Bestätigungen (Ok, das habe ich verstanden) müssen bestätigt werden. Ansonsten kein Speichern möglich</li>
                        <li style={{ marginBottom: '8px' }}><strong>Startseite USER:</strong> Lirum Larum Erinnerung - Hinweise zur 112 Notfallrettung oder zur Ersteinschätzung 116117.de (TODO für DRK-BOS)</li>
                        <li style={{ marginBottom: '8px' }}><strong>Stammdaten Infektionen</strong> - jetzt im ADMIN Bereich unter dem Menüpunkt "Administration" (Anlegen, Bearbeiten, Löschen)</li>
                    </ul>
                </Typography>
            </Paper>
        </Container>
    );
}
