import React, { useEffect, useState } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Switch,
    Grid,
    TextField
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de'; // Stellen sicher, dass das deutsche Locale geladen wird
import { useUser } from '../components/userContext';
import BooleanButtonGroup from '../components/buttonGroup';

const WhenStep = ({ formData, setFormData }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);
    const isRecurringEnabled = process.env.REACT_APP_ENABLE_RECURRING_FUNCTION === 'true';

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const now = dayjs();
    const minDate = dayjs().add(60, 'minutes');
    const maxDate = dayjs().add(10, 'years');

    dayjs.extend(updateLocale);
    // Replace "en" with the name of the locale you want to update.
    dayjs.updateLocale('de', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        dayjs.updateLocale('de', {
            weekStart: 1,
        });
    }, []); // Nur einmal beim ersten Mount ausführen

    const errorMessage = React.useMemo(() => {
        const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isBetween(now, minDate, null, '[]'); // '[]' bedeutet inklusiv, d.h., die Grenzen sind eingeschlossen

        switch (dateError) {
            case 'maxDate':
            case 'minDate':
            case 'minDateTime':
                return 'Ungültiges Datum. Bitte prüfen...';

            case 'minTime':
                if (isDepartureDateTimeValid) {
                    return 'Abfahrstdatum zu knapp. Bitte bedenken Sie, dass wir etwas Vorlaufzeit benötigen.';
                } else {
                    return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';
                }

            case 'disablePast':
                return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';

            case 'invalidDate':
                return 'Datum nicht korrekt';

            default:
                return '';
        }
        // eslint-disable-next-line
    }, [dateError]);

    const handleRecurringChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            is_recurring: event.target.checked,
        }));
    };

    const handleDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_days: {
                ...prevData.recurring_days,
                [day]: event.target.checked,
            },
        }));
    };

    const handleTimeChange = (day, time) => {
        // Überprüfen, ob eine gültige Uhrzeit eingegeben wurde
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_times: {
                    ...prevData.recurring_times,
                    [day]: time,
                },
            }));
        } else {
            // Gib eine Fehlermeldung oder Warnung aus, wenn keine gültige Uhrzeit eingegeben wurde
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    const handleTimeBlur = (event, day) => {
        handleTimeChange(day, event.target.value);
    };

    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', width: '100%', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend" style={{ color: formData.transport_type ? 'inherit' : '#E61F02' }}>Art des Transports</FormLabel>
                <RadioGroup
                    row
                    aria-label="transport_type"
                    name="transport_type"
                    value={formData.transport_type || ''}
                    onChange={handleChange}
                    disabled={isTransportApproved}
                >
                    <FormControlLabel value="TERMINFAHRT" style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }} control={<Radio />} label="Terminfahrt" disabled={isTransportApproved} />
                    <FormControlLabel value="ENTLASSUNG" style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }} control={<Radio />} label="Entlassung" disabled={isTransportApproved} />
                    <FormControlLabel value="EINWEISUNG" style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }} control={<Radio />} label="Einweisung" disabled={isTransportApproved} />
                    <FormControlLabel value="VERLEGUNG" style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }} control={<Radio />} label="Verlegung" disabled={isTransportApproved} />
                    <FormControlLabel value="DIALYSEFAHRT" style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }} control={<Radio />} label="Dialysefahrt" disabled={isTransportApproved} />
                </RadioGroup>
            </FormControl>
            <Box mt={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                    <DateTimePicker
                        label={formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                        name="departureDateTime"
                        value={formData.departureDateTime || null}
                        onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                        margin="normal"
                        fullWidth
                        ampm={false}
                        format="DD.MM.YYYY HH:mm"
                        disablePast
                        maxDate={maxDate}
                        minDateTime={minDate}
                        disabled={isTransportApproved}
                        onError={(newError) => setDateError(newError)}
                        slotProps={{
                            textField: {
                                error: dateError || !formData.departureDateTime, // Set error prop
                                helperText: errorMessage, // Set helper text
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>
            {(formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT') && (
                <Box mt={2}>
                    <BooleanButtonGroup
                        fieldName="ambulatory"
                        label="Ambulante Fahrt?"
                        options={[
                            { label: 'Ja', value: true },
                            { label: 'Nein', value: false },
                        ]}
                        formData={formData}
                        setFormData={setFormData}
                        customLabelStyle={{ width: '160px', marginRight: '2px' }}
                    />
                </Box>
            )}
            {isRecurringEnabled && (user?.role === 'dialysis' || user?.role === 'poweruser' || user?.role === 'admin') && (
                <Box mt={4}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Regelmäßiger Transport</FormLabel>
                        <FormControlLabel
                            control={<Switch checked={formData.is_recurring} onChange={handleRecurringChange} />}
                            label="Regelmäßig?"
                        />
                    </FormControl>
                    {formData.is_recurring && (
                        <Box mt={2}>
                            <div style={{ marginLeft: '2px', marginBottom: '24px', marginTop: '12px' }}>
                                <Typography variant="body1"> Einstellungen</Typography>
                                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>(Uhrzeit Ankunft Zielort pro Wochentag)</Typography>
                            </div>
                            <Grid container spacing={2}>
                                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => (
                                    <Grid item xs={12} sm={4} key={day}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!formData.recurring_days?.[day]}
                                                    onChange={(event) => handleDayChange(event, day)}
                                                />
                                            }
                                            label={day}
                                        />
                                        {formData.recurring_days?.[day] && (
                                            <TextField
                                                label="Uhrzeit"
                                                type="time"
                                                required
                                                value={formData.recurring_times?.[day] || ''}
                                                onChange={(event) => handleTimeChange(day, event.target.value)}
                                                onBlur={(event) => handleTimeBlur(event, day)}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: 300 }} // 5 min
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default WhenStep;