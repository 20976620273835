import React, { useState } from 'react';
import {
    Typography,
    TextField,
    Box,
} from '@mui/material';
import { useUser } from '../components/userContext';
import InstitutionAutocomplete from '../components/institutionAutocomplete';

const UserInformationStep = ({ formData, setFormData }) => {
    const { user } = useUser(); // Angemeldeter User.
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    const [phoneNumberError, setPhoneNumberError] = useState(false); // Für die Validierung der Telefonnummer

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Validierung für die Telefonnummer
        if (name === "phoneNumber") {
            const phoneNumberRegex = /^\+?[0-9\s]*$/; // Erlaubt nur das + Zeichen und Ziffern
            setPhoneNumberError(!phoneNumberRegex.test(value)); // Setzt Fehler auf true, wenn ungültig
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', width: '100%', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6" style={{ marginBottom: '30px' }}>Angaben zum Besteller</Typography>
            {user?.role !== 'user' && (
                <InstitutionAutocomplete
                    value={formData.institution_json || null}
                    style={{ marginBottom: '30px' }}
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            setFormData({
                                ...formData,
                                institution: '',
                                institution_json: null,
                                objectInstitution: '',
                                stationName: '',
                                phoneNumber: '',
                                from_institution: '',
                                from_station: '',
                                from_room: '',
                                from_street: '',
                                from_house_number: '',
                                from_postal_code: '',
                                from_city: ''
                            });
                        } else {
                            setFormData({
                                ...formData,
                                institution: newValue,
                                institution_json: newValue,
                                objectInstitution: newValue.object_institution,
                                stationName: newValue.station,
                                phoneNumber: newValue.phone_number || '',
                                from_institution: newValue,
                                from_institution_json: newValue,
                                from_station: newValue.station,
                                from_street: newValue.street_and_number.split(' ')[0],
                                from_house_number: newValue.street_and_number.split(' ')[1],
                                from_postal_code: newValue.postal_code,
                                from_city: newValue.city
                            });
                        }
                    }}
                    label="Namen der Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                    disabled={isTransportApproved}
                />
            )}
            <TextField
                label="Objekt / Einrichtung"
                name="objectInstitution"
                value={formData.objectInstitution || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
                error={!formData.objectInstitution}
                helperText={!formData.objectInstitution && 'Objekt / Einrichtung ist erforderlich'}
                disabled={isTransportApproved}
            />
            <TextField
                label="Station"
                name="stationName"
                value={formData.stationName || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
                error={!formData.stationName}
                helperText={!formData.stationName && 'Station ist erforderlich'}
                disabled={isTransportApproved}
            />
            <TextField
                label="Rückrufnummer"
                name="phoneNumber"
                value={formData.phoneNumber || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
                error={phoneNumberError || !formData.phoneNumber} // Fehler bei ungültiger Nummer oder leerem Feld
                helperText={
                    !formData.phoneNumber
                        ? 'Rückrufnummer ist erforderlich'
                        : phoneNumberError
                            ? 'Ungültige Telefonnummer. Nur "+" und Ziffern erlaubt.'
                            : ''
                }
                disabled={isTransportApproved}
            />
        </Box>
    );
};

export default UserInformationStep;