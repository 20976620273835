import React from 'react';
import { Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'© '}{' ' + new Date().getFullYear()} {' '}
                <Link color="inherit" href="https://www.drk-bos.de">
                    DRK-BOS
                </Link>
                {' | '}
                <Link color="inherit" href="/impressum">
                    Impressum
                </Link>
                {' | '}
                <Link color="inherit" href="/datenschutz">
                    Datenschutz
                </Link>
                {' | V 1.5.0'}
            </Typography>
        </div>
    );
}

export default Footer;
