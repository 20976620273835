import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Button,
    Divider,
    Box,
} from '@mui/material';
import PatientStep from './transportPatientStep';
import UserInformationStep from './transportUserStep';
import WhenStep from './transportWhenStep';
import FromToStep from './transportFromToStep';
import TransportDetailsStep from './transportDetailsStep';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../components/userContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // import plugin
dayjs.extend(utc); // use plugin

const SummaryStep = ({ formData }) => {
    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', width: '100%', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6">Zusammenfassung</Typography>
            <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Bestellung eines Krankentransportwagens</span>
                <br />
                <br />
                Sie bestellen einen KTW für den <span style={{ fontWeight: 'bold' }}>{formData.departureDateTime?.format('DD.MM.YYYY')}</span> um <span style={{ fontWeight: 'bold' }}>{formData.departureDateTime?.format('HH:mm')} Uhr </span> für folgenden Patienten:
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>
                    {formData.firstName} {formData.lastName}, Geburtsdatum: {formData.dateOfBirth.format('DD.MM.YYYY') || ''}
                </span>
                <br />
                <br />
                Der Transport startet in{' '}
                <span style={{ fontWeight: 'bold' }}>
                    {`${formData.from_street} ${formData.from_house_number}, ${formData.from_postal_code} ${formData.from_city}`}
                </span>{' '}
                und fährt{' '}
                <span style={{ fontWeight: 'bold' }}>
                    {`${formData.to_street} ${formData.to_house_number}, ${formData.to_postal_code} ${formData.to_city}`}
                </span>.
                <br />
                <br />

                Folgende Details wurden zum Transport eingegeben: <span style={{ fontWeight: 'bold' }}>{formData.transportDetails}</span>
                <br />
                <ul>
                    {formData.transport_mode && <li>Transportart: {formData.transport_mode === 'sitzend' ? 'Sitzend' : 'Liegend'}</li>}
                    {formData.own_wheelchair !== undefined && <li>Eigener Rollstuhl vorhanden: {formData.own_wheelchair ? 'Ja' : 'Nein'}</li>}
                    {formData.own_wheelchair === true && formData.folding !== undefined && <li>Rollstuhl klappbar: {formData.folding ? 'Ja' : 'Nein'}</li>}
                    {formData.luggage !== undefined && <li>Gepäck vorhanden: {formData.luggage ? 'Ja' : 'Nein'}</li>}
                    {formData.companion !== undefined && <li>Begleitperson: {formData.companion ? 'Ja' : 'Nein'}</li>}
                    {formData.oxygen_required !== undefined && <li>Sauerstoffpflichtig: {formData.oxygen_required ? 'Ja' : 'Nein'}</li>}
                    {formData.oxygen_required === true && formData.oxygen_source !== undefined && <li>Sauerstoff: {formData.oxygen_source === 'ab_patient' ? 'Ab Patient' : 'Ab Fahrzeug'}</li>}
                    {formData.prescription_present !== undefined && <li>Liegt eine gültige Verordnung vor: {formData.prescription_present ? 'Ja' : 'Nein'}</li>}
                    {formData.transport_mode === 'sitzend' && formData.insurance_approved !== undefined && <li>Krankenkassengenehmigung: {formData.insurance_approved ? 'Ja' : 'Nein'}</li>}
                    {formData.transport_mode === 'sitzend' && formData.insurance_approved === false && formData.confirm_insurance_approved !== undefined && <li>Kostenübernahme akzeptiert: {formData.confirm_insurance_approved ? 'Ja' : 'Nein'}</li>}
                </ul>
                Bitte bestellen Sie den Transport mit dem Klick auf "Absenden". Sie erhalten dann umgehend eine Bestätigung.
            </Typography>
        </Box>
    );
};

const TransportFormPage = () => {
    const { user } = useUser(); // Anbemeldeter User
    const steps = ['Besteller', 'Patient', 'Wann', 'Von/Nach', 'Transport'];
    const [activeStep, setActiveStep] = useState(0);
    const [fromToActiveTab, setFromToActiveTab] = useState(0);
    const [formData, setFormData] = useState({});
    const [stepErrors, setStepErrors] = useState(Array(steps.length).fill(false));
    const navigate = useNavigate();
    const [showSummary, setShowSummary] = useState(false);
    const { id } = useParams(); // ID aus der URL holen. Transport zum Bearbeiten
    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(id != null);
    const allowedRoles = ['user', 'dialysis'];

    useEffect(() => {
        const fetchUsersInstitution = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/institutions/${user?.institutionId}`, {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('useEffect user');
                    console.log(data);

                    const transformedFormData = {
                        // Füge die API-Daten in das formData-Objekt ein
                        user_id: data.user_id,
                        institution_id: data.institution_id,
                        institution: data,
                        objectInstitution: data.object_institution,
                        stationName: data.station,
                        phoneNumber: data.phone_number,
                        from_institution: data,
                        from_station: data.station,
                        from_street: data.street_and_number.split(' ')[0], // Annahme: Straße und Hausnummer sind durch ein Leerzeichen getrennt
                        from_house_number: data.street_and_number.split(' ')[1], // Annahme: Straße und Hausnummer sind durch ein Leerzeichen getrennt
                        from_postal_code: data.postal_code,
                        from_city: data.city

                    };
                    setFormData(transformedFormData);
                    //console.log(transformedFormData);
                    setActiveStep(1);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Transports:', error.message);
            }
        };

        if (user && isEditMode === false) {
            // Übernahme von Daten nur für normale User
            if (allowedRoles.includes(user.role)) {
                fetchUsersInstitution();
            }
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        const fetchTransport = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transport/${id}`, {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('useEffect id');
                    console.log(data);
                    const datePart = dayjs(data.departure_date).add(0, 'day').format('YYYY-MM-DD');
                    const timePart = data.departure_time;
                    const dateTimeString = `${datePart}T${timePart}`;
                    const parsedDate = dayjs(dateTimeString);

                    const transformedFormData = {
                        // Füge die API-Daten in das formData-Objekt ein
                        user_id: data.user_id,
                        status: data.status,
                        institution_id: data.institution_id,
                        institution: data.institution_json,
                        institution_json: data.institution_json,
                        objectInstitution: data.object_institution,
                        stationName: data.station,
                        phoneNumber: data.phone_number,
                        // ...
                        patient_id: data.patient_id,
                        firstName: data.first_name,
                        lastName: data.last_name,
                        dateOfBirth: data.birthdate ? dayjs(data.birthdate) : null,
                        insuranceNumber: data.insurance_number,
                        reasonForTransport: data.transport_reason,
                        infectious: data.is_infectious,
                        infektion_id: data.infektion_id,
                        // ...
                        transport_type: data.transport_type,
                        departureDateTime: parsedDate.isValid() ? parsedDate : null,

                        // Mappen der Von-Adresse
                        from_institution: data.from_institution_json,
                        from_institution_json: data.from_institution_json,
                        from_station: data.from_station,
                        from_room: data.from_room,
                        from_street: data.from_street,
                        from_house_number: data.from_house_number,
                        from_postal_code: data.from_postal_code,
                        from_city: data.from_city,
                        from_additional_info: data.from_additional_info,
                        // Mappen der Nach-Adresse
                        to_institution: data.to_institution_json,
                        to_institution_json: data.to_institution_json,
                        to_station: data.to_station,
                        to_room: data.to_room,
                        to_street: data.to_street,
                        to_house_number: data.to_house_number,
                        to_postal_code: data.to_postal_code,
                        to_city: data.to_city,
                        to_additional_info: data.to_additional_info,

                        transport_mode: data.transport_mode,
                        insurance_approved: data.insurance_approved,
                        ambulatory: data.ambulatory,
                        return_trip: data.return_trip,
                        is_recurring: data.is_recurring,
                        recurring_days: data.recurring_days,
                        recurring_times: data.recurring_times,
                        own_wheelchair: data.own_wheelchair,
                        folding: data.folding,
                        luggage: data.luggage,
                        companion: data.companion,
                        oxygen_required: data.oxygen_required,
                        oxygen_source: data.oxygen_source,
                        prescription_present: data.prescription_present,

                        weightChecked: true,
                        monitorRequired: false,
                        suctionRequired: false,
                        confirm_infectious: true,
                        confirm_weightChecked: true,
                        confirm_monitorRequired: true,
                        confirm_suctionRequired: true,
                        confirm_folding: true,
                        confirm_own_wheelchair: true,
                        confirm_luggage: true,
                        confirm_companion: true,
                        confirm_prescription_present: true,
                        confirm_insurance_approved: true,


                    };
                    setFormData(transformedFormData);
                    //console.log("Transformed Datas: ");
                    //console.log(transformedFormData);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Transports:', error.message);
            }
        };

        if (id) {
            fetchTransport();
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        // Validierung nach jeder Änderung in den FormDatas
        validateAllSteps();
        // eslint-disable-next-line
    }, [formData]);

    const handleNext = () => {
        const isValid = validateStep(activeStep);
        if (isValid) {
            setActiveStep((prevStep) => prevStep + 1);
        } else {
            if (activeStep === 3) {
                const isFromAddressComplete = !!formData.from_street &&
                    !!formData.from_house_number &&
                    !!formData.from_postal_code &&
                    !!formData.from_city;

                const isToAddressComplete = !!formData.to_street &&
                    !!formData.to_house_number &&
                    !!formData.to_postal_code &&
                    !!formData.to_city;

                // Überprüfe, ob die Abfahrtsadresse vorhanden ist, aber die Zieladresse nicht.
                if (isFromAddressComplete && !isToAddressComplete && fromToActiveTab === 0) {
                    setFromToActiveTab(1);
                }
            }
        }
    };


    const handleBack = () => {
        setShowSummary(false);
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const validateAllSteps = () => {
        // console.log("START validateAllSteps");
        for (let step = 0; step < steps.length; step++) {
            if (!validateStep(step)) {
                return false;
            }
        }
        return true;
    };

    const areAllStepsValid = () => {
        // Prüfen Sie, ob alle Werte in stepErrors false sind (keine Fehler vorhanden).
        return stepErrors.every((error) => error === false);
    };


    const validateStep = (step) => {
        let isValid = true;

        // Regulärer Ausdruck für die Telefonnummer
        const phoneNumberRegex = /^\+?[0-9\s]*$/; // Erlaubt nur das + Zeichen, Ziffern und Leerzeichen

        // Return true if valid, false otherwise
        if (step === 0) {
            isValid =
                !!formData.objectInstitution && // Überprüfung, ob das Feld nicht leer ist
                !!formData.stationName && // Überprüfung, ob das Feld nicht leer ist
                !!formData.phoneNumber && // Überprüfung, ob die Telefonnummer nicht leer ist
                phoneNumberRegex.test(formData.phoneNumber); // Überprüfung der Telefonnummer mit dem Regex
        }

        // For step 1 (PatientStep):
        if (step === 1) {
            const isDateValid = dayjs(formData.dateOfBirth, 'DD.MM.YYYY', true).isValid();
            const isDateInFuture = dayjs(formData.dateOfBirth, 'DD.MM.YYYY').isAfter(dayjs().add(1, 'day'));
            const isDateInPast = dayjs(formData.dateOfBirth, 'DD.MM.YYYY').isBefore(dayjs().subtract(120, 'years'));

            isValid =
                !!formData.firstName &&
                !!formData.lastName &&
                isDateValid &&
                !isDateInPast &&
                !isDateInFuture &&
                (formData.infectious === true || formData.infectious === false) &&
                (
                    (formData.infectious === false) ||  // Wenn nicht infektiös, dann ist die Regel erfüllt
                    (!!formData.infektion_id && formData.infektion_id !== null)  // Wenn infektiös, dann muss infektion_id vorhanden sein
                ) &&
                formData.weightChecked === true
                ;
        }

        // For step 2 (WhenStep):
        if (step === 2) {
            const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isAfter(dayjs().add(1, 'hour'));
            const isDepartureDateTimeInFarFuture = dayjs(formData.departureDateTime).isAfter(dayjs().add(10, 'years'));

            isValid =
                !!formData.transport_type &&
                !!formData.departureDateTime &&
                isDepartureDateTimeValid &&
                !isDepartureDateTimeInFarFuture
                ;
        }

        // For step 3 (FromToStep):
        if (step === 3) {
            const isFromAddressComplete = !!formData.from_street &&
                !!formData.from_house_number &&
                !!formData.from_postal_code &&
                !!formData.from_city;

            const isToAddressComplete = !!formData.to_street &&
                !!formData.to_house_number &&
                !!formData.to_postal_code &&
                !!formData.to_city;

            // Überprüfe, ob sowohl die Abfahrtsadresse als auch die Zieladresse vollständig sind.
            isValid = isFromAddressComplete && isToAddressComplete;

        }

        // For step 4 (Transport):
        if (step === 4) {
            isValid =
                !!formData.transport_mode &&
                (formData.own_wheelchair === true || formData.own_wheelchair === false) &&
                (formData.luggage === true || formData.luggage === false) &&
                (formData.companion === true || formData.companion === false) &&
                (formData.prescription_present === true || formData.prescription_present === false) &&
                (formData.oxygen_required === true || formData.oxygen_required === false) && // Hinzufügen der Bedingung für oxygen_required
                (formData.oxygen_required === false || (formData.oxygen_required === true && (formData.oxygen_source === 'ab_patient' || formData.oxygen_source === 'ab_fahrzeug'))) && // Hinzufügen der Bedingung für oxygen_source
                // Hinzufügen der Bedingung für confirm_own_wheelchair
                (!formData.hasOwnProperty('folding') || formData.folding === true || formData.folding === null || (formData.folding === false && formData.confirm_folding === true)) &&
                (formData.luggage === false || (formData.luggage === true && formData.confirm_luggage === true)) &&
                (formData.companion === false || (formData.companion === true && formData.confirm_companion === true)) &&
                (formData.prescription_present === true || (formData.prescription_present === false && formData.confirm_prescription_present === true)) &&
                (formData.transport_mode !== 'sitzend' || formData.insurance_approved === true || (formData.insurance_approved === false && formData.confirm_insurance_approved === true))
                ;
            //console.log("Validation of step 4: " + isValid);
            //console.log(formData);
        }

        // Set the error for the current step in stepErrors
        setStepErrors((prevErrors) => {
            const updatedErrors = [...prevErrors];
            updatedErrors[step] = !isValid;
            return updatedErrors;
        });

        return isValid;
    };

    const handleSubmit = () => {
        if (validateAllSteps()) {
            setShowSummary(true);
        }
    };

    const ensureRecurringTimes = (recurringTimes) => {
        const defaultTime = "08:00";

        // Überprüfen und setzen der Standardzeit, wenn keine Zeit angegeben ist
        for (let day in recurringTimes) {
            if (!recurringTimes[day] || recurringTimes[day].trim() === "") {
                recurringTimes[day] = defaultTime;
            }
        }
    };

    const handleFinalSubmit = async () => {
        if (!validateAllSteps()) {
            return;
        }

        // Überprüfen und Sicherstellen, dass keine leeren Zeiten in recurring_times existieren
        if (formData.is_recurring && formData.recurring_times) {
            ensureRecurringTimes(formData.recurring_times);
        }

        console.log(formData);
        // Konvertiere die UI-Feldnamen in API-Feldnamen
        const apiData = {
            user_id: formData.user_id ? formData.user_id : user.user_id,
            status: formData.status ? formData.status : 'pending',  // FIXME for admins
            institution_id: formData.institution?.institution_id,
            patient_institution_id: user?.institutionId,
            object_institution: formData.objectInstitution,
            station: formData.stationName,
            phone_number: formData.phoneNumber,
            // ...
            patient_id: formData.patient_id,
            first_name: formData.firstName,
            last_name: formData.lastName,
            birthdate: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : '',
            insurance_number: formData.insuranceNumber,
            transport_reason: formData.reasonForTransport,
            is_infectious: formData.infectious,
            infektion_id: formData.infektion_id,
            // ...
            transport_type: formData.transport_type,
            departure_date: formData.departureDateTime ? formData.departureDateTime.format('YYYY-MM-DD') : '',
            departure_time: formData.departureDateTime ? formData.departureDateTime.format('HH:mm:ss') : '',
            // Mappen der Von-Adresse
            from_institution_id: formData.from_institution?.institution_id,
            from_institution: formData.from_institution?.object_institution,
            from_station: formData.from_station,
            from_room: formData.from_room,
            from_street: formData.from_street,
            from_house_number: formData.from_house_number,
            from_postal_code: formData.from_postal_code,
            from_city: formData.from_city,
            from_additional_info: formData.from_additional_info,
            // Mappen der Nach-Adresse
            to_institution_id: formData.to_institution?.institution_id,
            to_institution: formData.to_institution?.object_institution,
            to_station: formData.to_station,
            to_room: formData.to_room,
            to_street: formData.to_street,
            to_house_number: formData.to_house_number,
            to_postal_code: formData.to_postal_code,
            to_city: formData.to_city,
            to_additional_info: formData.to_additional_info,

            transport_mode: formData.transport_mode,
            insurance_approved: formData.insurance_approved,
            ambulatory: formData.ambulatory,
            return_trip: formData.return_trip,
            is_recurring: formData.is_recurring,
            recurring_days: formData.recurring_days,
            recurring_times: formData.recurring_times,
            own_wheelchair: formData.own_wheelchair,
            folding: formData.folding,
            luggage: formData.luggage,
            companion: formData.companion,
            oxygen_required: formData.oxygen_required,
            oxygen_source: formData.oxygen_source,
            prescription_present: formData.prescription_present,
        };

        try {
            const apiUrl = isEditMode
                ? `${process.env.REACT_APP_API_URI}/api/transports/${id}`
                : `${process.env.REACT_APP_API_URI}/api/transports`;

            const method = isEditMode ? 'PUT' : 'POST';

            const response = await fetch(apiUrl, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key,
                },
                body: JSON.stringify(apiData),
            });

            if (response.ok) {
                const newTransport = await response.json();
                console.log('New transport created:', newTransport);
                // Handle successful creation, navigation, etc.
                navigate('/transportlist');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Error creating transport:', error.message);
            // Handle error case
        }
    };


    const handleAbbort = () => {
        // Handle form submission to create or edit a transport record
        navigate(-1);
    };


    const renderStepContent = (step) => {
        if (showSummary) {
            return <SummaryStep formData={formData} />;
        }
        switch (step) {
            case 0:
                return <UserInformationStep formData={formData} setFormData={setFormData} />;
            case 1:
                return <PatientStep formData={formData} setFormData={setFormData} />;
            case 2:
                return <WhenStep formData={formData} setFormData={setFormData} />;
            case 3:
                return <FromToStep formData={formData} setFormData={setFormData} activeTab={fromToActiveTab} />;
            case 4:
                return <TransportDetailsStep formData={formData} setFormData={setFormData} />;
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="md" >
            <Typography variant="h5" color="#E46450" gutterBottom mb={2}>
                {isEditMode ? 'Transport bearbeiten' : 'Transport eingeben'}
            </Typography>
            <Box sx={{ display: 'flex', position: 'relative', }} justifyContent="center"  >
                <Box mt={4}
                    style={{ background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', padding: '20px', margin: '0 auto', width: '80%', border: '1px solid #F5F5F5', position: 'absolute', zIndex: 2, }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel error={stepErrors[index]} onClick={() => handleStepChange(index)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {renderStepContent(activeStep)}
            </Box>
            <Divider />
            <Box mt={2} mb={4}>
                <div>
                    {(formData.weightChecked === false || formData.monitorRequired === true || formData.suctionRequired === true) ? (
                        <span>
                            {activeStep !== 1 &&
                                <Typography variant="body1" color="error">
                                    Der Transport kann nicht für Patienten mit einem Gewicht von 150 kg oder mehr angefordert werden.
                                </Typography>
                            }
                            <Button onClick={handleAbbort} variant="outlined" sx={{ mt: 2 }}>
                                Eingabe abbrechen
                            </Button>
                        </span>
                    ) : (
                        <Box display="flex" justifyContent="space-between">
                            <div>
                                {activeStep > 0 && <Button variant="outlined" onClick={handleBack}>Zurück</Button>}
                                <Button onClick={handleAbbort} variant="text" sx={{ ml: 1 }}>
                                    Eingabe abbrechen
                                </Button>
                            </div>
                            <div>
                                {activeStep < steps.length - 1 && (
                                    <>
                                        <Button variant="outlined" onClick={handleNext}>Weiter</Button>
                                        {isEditMode && (
                                            <Button variant="outlined" onClick={handleFinalSubmit} disabled={!areAllStepsValid()} sx={{ ml: 1 }}>
                                                Speichern
                                            </Button>
                                        )}
                                    </>
                                )}
                                {activeStep === steps.length - 1 && (
                                    <>
                                        {!areAllStepsValid() && (
                                            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                                Es fehlen noch Pflichtangaben. Bitte Eingaben prüfen.
                                            </Typography>
                                        )}
                                        <Button variant="outlined" onClick={showSummary || isEditMode ? handleFinalSubmit : handleSubmit} disabled={!areAllStepsValid()}>
                                            {showSummary || isEditMode ? 'Absenden' : 'Speichern'}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Box>
                    )}
                </div>
            </Box>




        </Container>
    );
};

export default TransportFormPage;
